<template>
  <div class="courier-name-override-form card">
    <div class="card-content">
      <div class="form-header">
        {{ $t('print.courierNameOverrideForm.formHeader') }}
      </div>
      <form @submit.prevent>
        <b-field :label="$t('print.courierNameOverrideForm.formLabels.courierName')">
          <b-input
            v-model="courierName"/>
        </b-field>
        <b-field :label="$t('print.courierNameOverrideForm.formLabels.orderIdentifiers')">
          <b-input
            v-model="orderIdentifiers"/>
        </b-field>
        <div class="buttons">
          <b-button @click="cancelForm">
            {{ $t('print.courierNameOverrideForm.actions.cancel') }}
          </b-button>
          <b-button
            type="is-primary"
            @click="confirmForm">
            {{ $t('print.courierNameOverrideForm.actions.confirm') }}
          </b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'courier-name-override-form',
  data() {
    return {
      courierName: null,
      orderIdentifiers: null
    }
  },
  methods: {
    cancelForm() {
      this.$parent.close()
    },
    confirmForm() {
      this.$emit('confirm', { courierName: this.courierName, orderIdentifiers: this.orderIdentifiers })
    }
  }
}
</script>

<style lang="scss" scoped>
.courier-name-override-form {
  .card-content {
    padding: $space-s $space-m $space-l;

    .form-header {
      @extend %heading;
    }

    form {
      .field::v-deep {
        &:not(:last-child) {
          margin-bottom: $space-m;
        }

        label {
          color: $grey;
          @extend %small;
        }

        input {
          @extend %body;

          &[readonly] {
            background-color: $grey-lighter;
          }
        }
      }

      .buttons {
        display: flex;
        margin-top: $space-l;

        > * {
          flex-grow: 1;
        }
      }
    }
  }
}
</style>
